import React, { FC, ReactElement } from 'react';

import AwardsLogoBoxes from 'components/AwardsLogoBoxes';
import BehaviourAndStrategyBoxes from 'components/BehaviourAndStrategyBoxes';
import Benefits from 'components/Benefits';
import BrandPurposeAndSdg from 'components/BrandPurposeAndSdg';
import BrandsCarousel from 'components/BrandsCarousel';
import CareersBanner from 'components/CareersBanner';
import CaseStudySlider from 'components/CaseStudySlider';
import ColourfulQuoteSlider from 'components/ColourfulQuoteSlider';
import ContactUsInformation from 'components/ContactUsInformation';
import ContentBlock from 'components/ContentBlock';
import CtaCards from 'components/CtaCards';
import FactsWithTabs from 'components/FactsWithTabs';
import FlexibleStatisticIcons from 'components/FlexibleStatisticIcons';
import FullWidthVideo from 'components/FullWidthVideo';
import GlobalReachMap from 'components/GlobalReachMap/GlobalReachMap';
import GpgMap from 'components/GpgMap';
import HeadingAndText from 'components/HeadingAndText';
import HealthierPlanetScroller from 'components/HealthierPlanetScroller';
import HeroQuote from 'components/HeroQuote';
import HorizontalLine from 'components/HorizontalLine';
import IFrame from 'components/IFrame';
import ImageAndVideoSlider from 'components/ImageAndVideoSlider';
import InfographicImage from 'components/InfographicImage';
import InvestorsBanner from 'components/InvestorsBanner';
import NewsAndStories from 'components/NewsAndStories';
import OurBrandsScroller from 'components/OurBrandsScroller';
import PlainAccordion from 'components/PlainAccordion';
import RelatedNews from 'components/RelatedNews';
import SimpleCtaBlocks from 'components/SimpleCtaBlocks';
import SingleCta from 'components/SingleCta';
import Skills from 'components/Skills';
import SocialFeed from 'components/SocialFeed';
import StatisticsBasic from 'components/StatisticsBasic';
import StatisticsWithImages from 'components/StatisticsWithImages';
import StrategyAccordion from 'components/StrategyAccordion';
import SwitcherBlock from 'components/SwitcherBlock';
import Table from 'components/Table';
import ThreePeopleCardProfiles from 'components/ThreePeopleCardProfiles';
import Timeline from 'components/Timeline';
import TitleAndDownload from 'components/TitleAndDownload';
import TwoRowsStatsAndContent from 'components/TwoRowsStatsAndContent';
import VideoAndImageWithText from 'components/VideoAndImageWithText';

import { BodyRendererProps } from './models';

const Components = {
  'Heading And Text': HeadingAndText,
  'Statistics With Images': StatisticsWithImages,
  'CTA Cards': CtaCards,
  'News And Stories': NewsAndStories,
  'Video And Image With Text': VideoAndImageWithText,
  'Full Width Video': FullWidthVideo,
  'Statistics Basic': StatisticsBasic,
  'Brands Carousel': BrandsCarousel,
  'Case Study Slider': CaseStudySlider,
  'Colourful Quote Slider': ColourfulQuoteSlider,
  'Healthier Planet Scroller': HealthierPlanetScroller,
  'Content Block': ContentBlock,
  'Hero Quote': HeroQuote,
  'Infographic Image': InfographicImage,
  'Switcher Block': SwitcherBlock,
  'Awards Logo Boxes': AwardsLogoBoxes,
  'Simple CTA Blocks': SimpleCtaBlocks,
  'Global Reach Map': GlobalReachMap,
  'Related News': RelatedNews,
  'Contact Us Information': ContactUsInformation,
  IFrame,
  'Single CTA': SingleCta,
  'Brand Purpose And SDG': BrandPurposeAndSdg,
  'Three People Card Profiles': ThreePeopleCardProfiles,
  'Title And Download': TitleAndDownload,
  'Our Brands Scroller': OurBrandsScroller,
  'Flexible Statistic Icons': FlexibleStatisticIcons,
  'Horizontal Line': HorizontalLine,
  'Image And Video Slider': ImageAndVideoSlider,
  Benefits,
  'Two Rows Stats And Content': TwoRowsStatsAndContent,
  'Plain Accordion': PlainAccordion,
  'Strategy Accordion': StrategyAccordion,
  'Social Feed': SocialFeed,
  'Behaviour And Strategy Boxes': BehaviourAndStrategyBoxes,
  Table,
  Timeline,
  Skills,
  'Facts With Tabs': FactsWithTabs,
  'GPG Map': GpgMap,
  'Careers Banner': CareersBanner,
  'Investors Banner': InvestorsBanner,
};
 
const ComponentsFlattenData = ['Careers Banner', 'Investors Banner'];

const BodyRenderer: FC<BodyRendererProps> = ({ modules }): ReactElement => (
  <>
    {modules?.map((block, index) => {
      if (typeof Components[block.structure] === 'undefined') {
        return null;
      }

      const Component = Components[block.structure];

      return ComponentsFlattenData.includes(block.structure) ? (
        <Component key={`${block.structure}${index}`} {...block.module} />
      ) : (
        <Component key={`${block.structure}${index}`} module={block.module} />
      );
    })}
  </>
);

export default BodyRenderer;
